<template>
  <div>
    <TableHeader
      :enable-export="true"
      :show-statistics="true"
      :file-name="fileName"
      :items-csv="itemsCsv"
      :per-page="perPage"
      :page-options="pageOptions"
      :current-page="currentPage"
      :sort-by="sortBy"
      :sort-options="sortOptions"
      :filter="filter"
      :sort-desc="sortDesc"
      @reset:filter="filter = ''"
      @update:current-page="(page) => getRows(perPage, page)"
      @update:per-page="(pp) => getRows(pp, currentPage)"
      @update:sort-by="(sb) => { sortBy = sb }"
      @update:sort-desc="(sd) => { sortDesc = sd }"
      @update:filter="(f) => { filter = f }"
    />
    <b-row>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(questions)="data">
            <b-row>
              <span @click="showModelListChoices(data)">
                {{ data.value["TitleEN"] }}
              </span>
            </b-row>
          </template>

          <template #cell(user)="data">
            <b-row>
              <span @click="showModelListChoices(data)">
                {{ data.value["FirstName"] }} ({{ data.value["email"] }})
              </span>
            </b-row>
          </template>

          <template #cell(created)="data">
            <b-row>
              <span @click="showModelListChoices(data)">
                {{ formatDate(data.value) }}
              </span>
            </b-row>
          </template>

          <!-- formatDate -->
          <template #cell(action)="data">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item @click="showModalResearchAnswers(data)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Details</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showComfirmDeleteQuestion(data)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>
        </b-table>
      </b-col>

      <!-- modal update Research -->
      <b-modal
        id="modal-prevent-closing"
        ref="my-modal-update-user"
        title="Update Research"
        ok-title="Submit"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-form-group
            v-if="selectedResearch"
            :state="nameState"
            label="Title EN"
            label-for="name-input"
            invalid-feedback="Title is required"
          >
            <b-form-input
              id="name-input"
              v-model="selectedResearch.item.TitleEN"
              :state="nameState"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="selectedResearch"
            :state="nameState"
            label="Title AR"
            label-for="name-input"
            invalid-feedback="Title AR is required"
          >
            <b-form-input
              id="name-input"
              v-model="selectedResearch.item.TitleAR"
              :state="nameState"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="selectedResearch"
            :state="nameState"
            label="Description AR"
            label-for="name-input"
            invalid-feedback="Description AR is required"
          >
            <b-form-input
              id="name-input"
              v-model="selectedResearch.item.DescriptionAR"
              :state="nameState"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="selectedResearch"
            :state="nameState"
            label="Description EN "
            label-for="name-input"
            invalid-feedback="Description EN is required"
          >
            <b-form-input
              id="name-input"
              v-model="selectedResearch.item.DescriptionEN"
              :state="nameState"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="selectedResearch"
            :state="nameState"
            label="Age"
            label-for="name-input"
            invalid-feedback="Age is required"
          >
            <b-form-input
              id="name-input"
              v-model="selectedResearch.item.Age"
              :state="nameState"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="selectedResearch"
            :state="nameState"
            label="Dr Name"
            label-for="name-input"
            invalid-feedback="Dr Name is required"
          >
            <b-form-input
              id="name-input"
              v-model="selectedResearch.item.drName"
              :state="nameState"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="selectedResearch"
            :state="nameState"
            label="Earns AR"
            label-for="name-input"
            invalid-feedback="Earns AR is required"
          >
            <b-form-input
              id="name-input"
              v-model="selectedResearch.item.EarnsAR"
              :state="nameState"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="selectedResearch"
            :state="nameState"
            label="Earns EN "
            label-for="name-input"
            invalid-feedback="Earns EN is required"
          >
            <b-form-input
              id="name-input"
              v-model="selectedResearch.item.EarnsEN"
              :state="nameState"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="selectedResearch"
            :state="nameState"
            label="Game"
            label-for="name-input"
            invalid-feedback="Game is required"
          >
            <b-form-input
              id="name-input"
              v-model="selectedResearch.item.GameId"
              :state="nameState"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="selectedResearch"
            :state="nameState"
            label="Game Level"
            label-for="name-input"
            invalid-feedback="Game Level is required"
          >
            <b-form-input
              id="name-input"
              v-model="selectedResearch.item.gameLevel"
              :state="nameState"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="selectedResearch"
            :state="nameState"
            label="Instructions"
            label-for="name-input"
            invalid-feedback="Instructions is required"
          >
            <b-form-input
              id="name-input"
              v-model="selectedResearch.item.Instructions"
              :state="nameState"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="selectedResearch"
            :state="nameState"
            label="Is Game"
            label-for="name-input"
            invalid-feedback="Is Game is required"
          >
            <b-form-input
              id="name-input"
              v-model="selectedResearch.item.isGame"
              :state="nameState"
              required
            />
          </b-form-group>
          <!-- {{ researchesTypes[0].name }}
          {{ selectedResearch.item.ResearchType}} -->

          <b-form-group v-if="selectedResearch">
            <label
              class="d-inline-block text-sm-left mr-50"
              for="researchType-input"
            >Research Type</label>
            <v-select
              id="researchType-input"
              v-model="selectedResearch.item.ResearchType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :options="researchesTypes"
              label="name"
            >
              <template #option="item">
                <span class="mr-1 mb-sm-0"> {{ item.name }}</span>
              </template>
            </v-select>
          </b-form-group>
        </form>
      </b-modal>
      <!-- modal update user -->

      <!-- modal add Question -->
      <b-modal
        id="modal-prevent-closing"
        ref="my-modal-add-question"
        title="ADD Question"
        ok-title="Submit"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOkAddQuestion"
      >
        <validation-observer ref="addQuestion">
          <form>
            <b-form-group
              :state="nameState"
              label="Titel En"
              label-for="titleEn-input"
              invalid-feedback="Title En is required"
            >
              <validation-provider
                #default="{ errors }"
                name="Titel EN"
                rules="required"
              >
                <b-form-input
                  id="title-input"
                  v-model="titleEn"
                  :state="titleEnState"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :state="nameState"
              label="Titel AR"
              label-for="titleAr-input"
              invalid-feedback="Title Ar is required"
            >
              <validation-provider
                #default="{ errors }"
                name="Titel AR"
                rules="required"
              >
                <b-form-input
                  id="titelAr-input"
                  v-model="titleAr"
                  :state="titleArState"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Mark:- description EN -->
            <b-form-group
              :state="descriptionEnState"
              label="Description En"
              label-for="descriptionEn-input"
              invalid-feedback="Description En is required"
            >
              <validation-provider
                #default="{ errors }"
                name="Description EN"
                rules="required"
              >
                <b-form-input
                  id="descriptionEn-input"
                  v-model="descriptionEn"
                  :state="descriptionEnState"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Mark:- description AR -->
            <b-form-group
              :state="descriptionArState"
              label="Description Ar"
              label-for="descriptionAr-input"
              invalid-feedback="Description Ar is required"
            >
              <validation-provider
                #default="{ errors }"
                name="Description Ar"
                rules="required"
              >
                <b-form-input
                  id="descriptionAr-input"
                  v-model="descriptionAr"
                  :state="descriptionArState"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <span> Resources</span>
            <b-row class="p-2">
              <b-form-checkbox
                v-model="selectedImage"
                class="pr-2"
                value="A"
              >
                Image
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedVideo"
                class="pr-2"
                value="A"
              >
                Video
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedAudio"
                class="pr-2"
                value="A"
              >
                Audio
              </b-form-checkbox>
            </b-row>

            <b-form-group v-if="selectedImage">
              <label
                class="d-inline-block text-sm-left mr-50"
                for="age-input"
              >Image</label>
              <b-form-file
                id="image-input"
                v-model="fileImage"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept="image/*"
              />
            </b-form-group>

            <b-form-group v-if="selectedVideo">
              <label
                class="d-inline-block text-sm-left mr-50"
                for="video-input"
              >Video</label>
              <b-form-file
                id="video-input"
                v-model="fileVideo"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept="video/*"
              />
            </b-form-group>

            <b-form-group v-if="selectedAudio">
              <label
                class="d-inline-block text-sm-left mr-50"
                for="audio-input"
              >Audio</label>
              <b-form-file
                id="audio-input"
                v-model="fileAudio"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept="audio/*"
              />
            </b-form-group>

            <!-- Mark:- Number of Choices -->
            <b-form-group
              :state="numberOfChoicesState"
              label="Number of Choices"
              label-for="numberOfChoices-input"
              invalid-feedback="Number of Choices is required"
            >
              <validation-provider
                #default="{ errors }"
                name="Number of Choices"
                rules="required|between:1,100"
              >
                <b-form-input
                  id="age-input"
                  v-model="numberOfChoices"
                  :state="numberOfChoicesState"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Mark list of choices -->
            <div
              v-for="(choice, index) in choices"
              :key="index"
            >
              <b-form-group
                :state="choice.enstate"
                :label="choiceLable(index + 1, 'EN')"
                label-for="Choice1En-input"
                invalid-feedback="Choice 1 EN is required"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="choiceLable(index + 1, 'EN')"
                  rules="required"
                >
                  <b-form-input
                    id="Choice1En-input"
                    v-model="choice.enKey"
                    :state="choice.enstate"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                :state="choice.arstate"
                :label="choiceLable(index + 1, 'AR')"
                label-for="Choice2Ar-input"
                invalid-feedback="Choice 2 AR is required"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="choiceLable(index + 1, 'AR')"
                  rules="required"
                >
                  <b-form-input
                    id="Choice2Ar-input"
                    v-model="choice.arKey"
                    :state="choice.arstate"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <b-form-group v-if="currentResearchCorrect">
              <span style="font-size: 12px">Answer Choices</span>
              {{ numberOfChoices.num }}
              <v-select
                v-model="answerNum"
                title="Select an item"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="AnswerArray"
                label="num"
              >
                <template #option="item">
                  <span> {{ item.num }}</span>
                </template>
              </v-select>
            </b-form-group>

            <b-form-group>
              <span style="font-size: 12px">Question with Timer</span>
              <v-select
                v-model="hasTimer"
                title="Select an item"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[{ val: true }, { val: false }]"
                label="val"
              >
                <template #option="item">
                  <span> {{ item.val }}</span>
                </template>
              </v-select>
            </b-form-group>
          </form>
        </validation-observer>
      </b-modal>
      <!-- modal update user -->

      <!-- modal list cards -->
      <b-modal
        id="modal-prevent-closing"
        ref="my-modal-user-choices"
        title="Choices"
        ok-only
        ok-title="ok"
      >
        <div
          v-for="(item, index) in currentChoices"
          :key="item.id"
        >
          <span
            class="pt-1 pl-1"
            style="color: red"
          > choice {{ index }} </span>
          <b-row class="pt-1 pl-1">
            <span class="pt-1 pl-1"> EN -> {{ item.enKey }} </span>
          </b-row>
          <b-row class="pt-1 pl-1">
            <span class="pt-1 pl-1"> AR -> {{ item.arKey }} </span>
          </b-row>
          <hr class=".divider-Dark">
        </div>
      </b-modal>
      <!-- End modal list cards -->
      <b-col cols="1">
        {{ currentPage !== Math.ceil(totalRows / perPage) ? perPage : totalRows % perPage }} - {{ totalRows }}
      </b-col>
      <b-col cols="11">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* global Backendless */

import {
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@validations'
import TableHeader from '@/components/Genirecs/TableHeader.vue'

export default {
  components: {
    TableHeader,
    ValidationProvider,
    ValidationObserver,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BFormInput,
    BDropdown,
    BDropdownItem,
    vSelect,
    BFormCheckbox,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fileName: 'data',
      required,
      email: email ?? '',
      selectedImage: false,
      selectedVideo: false,
      selectedAudio: false,
      fileImage: null,
      fileVideo: null,
      fileAudio: null,
      username: '',
      researchID: '',
      numberOfChoices: 2,
      answerNum: '',
      hasTimer: false,
      titleEn: '',
      titleAr: '',
      descriptionEn: '',
      descriptionAr: '',
      choice1En: '',
      choice2En: '',
      choice3En: '',
      choice4En: '',
      choice1Ar: '',
      choice2Ar: '',
      choice3Ar: '',
      choice4Ar: '',
      password: '',
      user: '',
      cardsParseObjects: [],
      currentChoices: [],
      titleEnState: null,
      titleArState: null,
      descriptionEnState: null,
      descriptionArState: null,
      numberOfChoicesState: null,
      nameState: null,
      emailState: null,
      passwordState: null,
      perPage: 50,
      pageOptions: [50, 75, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'questions', label: 'Questions Title' },
        { key: 'description', label: 'Description', sortable: true },
        { key: 'user', label: 'User', sortable: true },
        { key: 'created', label: 'Created', sortable: true },
        { key: 'answerTime', label: 'User Answer Time', sortable: true },
        {
          key: 'correctAnswerIndex',
          label: 'Correct Answer Index',
          sortable: true,
        },
        { key: 'userChoice', label: "User Answer's Index", sortable: true },
        {
          key: 'userChoiceValue',
          label: "User Answer's Value",
          sortable: true,
        }, {
          key: 'isCorrect',
          label: 'Is Correct',
          sortable: true,
        },
        'action',
      ],
      items: [],
      itemsCsv: [],
      AnswerArray: [{ num: 1 }, { num: 2 }],
      researchesTypes: [],
      currentResearch: {},
      currentResearchType: '',
      currentResearchCorrect: false,
      cards: [],
      selectedResearch: null,
      selected1: null,

      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
      userId: Backendless.LocalCache.get('current-user-id'),
      choices: [
        { enKey: '', arKey: '' },
        { enKey: '', arKey: '' },
      ],
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    numberOfChoices() {
      this.choices = []
      this.AnswerArray = []
      for (let i = 0; i < this.numberOfChoices; i += 1) {
        this.choices.push({ enKey: '', arKey: '' })
        this.AnswerArray.push({ num: i + 1 })
      }
    },
  },
  mounted() {
    this.researchID = this.$route.params.entryId
    this.ResearchesTypes()
    this.getRows()
  },
  methods: {
    formatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
    choiceLable(index, lang) {
      return `Choice ${index} ${lang}`
    },
    makeid(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      let counter = 0
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        )
        counter += 1
      }

      const currentTimestamp = moment(new Date()).format(
        'MM_DD_YYYY_h_mm_ss_SSS',
      )
      return result + currentTimestamp
    },
    async addNewQuestion() {
      const that = this
      let image = null
      let video = null
      let audio = null
      if (this.selectedImage) {
        image = await Backendless.Files.upload(
          this.fileImage,
          `/myfiles/${this.makeid(7)}`,
        )
      }
      if (this.selectedVideo) {
        video = await Backendless.Files.upload(
          this.fileVideo,
          `/myfiles/${this.makeid(7)}`,
        )
      }
      if (this.selectedVideo) {
        audio = await Backendless.Files.upload(
          this.fileAudio,
          `/myfiles/${this.makeid(7)}`,
        )
      }

      const question = {
        Type: this.currentResearchType,
        ResearchID: this.researchID,
        TitleEN: this.titleEn,
        TitleAR: this.titleAr,
        DescriptionEN: this.descriptionEn,
        DescriptionAR: this.descriptionAr,
        NumberOfChoices: parseInt(this.numberOfChoices, 10),
        Choices: this.choices,

        AnswerChoiceNum: this.answerNum.num,
        Image: image != null ? image.fileURL : null,
        Video: video != null ? video.fileURL : null,
        Audio: audio != null ? audio.fileURL : null,
        Timer: this.hasTimer,
        ownerId: this.userId,
      }

      Backendless.Data.of('Questions')
        .save(question)
        .then(savedObject => {
          that.$refs['my-modal-add-question'].hide()
          that.currentResearch.questions.push(savedObject)

          const children = that.currentResearch.questions

          Backendless.Data.of('Researches')
            .setRelation(that.currentResearch, 'questions', children)
            .then(() => {
              that.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Question added Successfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.getRows()
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    },
    showModelListChoices(research) {
      this.currentChoices = research.item.choices
      if (this.currentChoices.length > 0) {
        this.$refs['my-modal-user-choices'].show()
      }
    },
    showModalResearchAnswers(research) {
      this.$router.push({
        name: 'researches-answers-questions',
        params: { id: research.item.id, qid: research.item.ResearchType },
      })
    },
    resetModal() {
      this.name = ''
      this.email = ''
      this.user = ''
      this.nameState = null
      this.emailState = null
      this.passwordState = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleOkAddQuestion(bvModalEvt) {
      bvModalEvt.preventDefault()

      this.$refs.addQuestion.validate().then(success => {
        if (success) {
          this.handleSubmitAddQuestion()
        }
      })
    },
    handleSubmit() {

    },
    handleSubmitAddQuestion() {
      this.$refs.addQuestion.validate().then(success => {
        if (success) {
          this.addNewQuestion()
        }
      })
    },

    showComfirmDeleteQuestion(question) {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to delete Question ${question.item.titleEn} .`,
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            const that = this
            Backendless.Data.of('Questions')
              .remove({ objectId: question.item.id })
              .then(() => {
                that.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Offer Deleted Successfully',
                    icon: 'BellIcon',
                    text: question.item.titleEn,
                    variant: 'success',
                  },
                })
                that.getRows()
              })
              .catch(() => {
              })
          }
        })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getRows() {
      const that = this
      that.researchesTypes = []
      that.items = []

      const whereClause = `ResearchAnswers[Questions].objectId = '${this.$route.params.entryId}'`

      const queryBuilder = Backendless.DataQueryBuilder.create()
      queryBuilder.setRelationsDepth(2)
      queryBuilder.setPageSize(100).setOffset(0)
      queryBuilder.setWhereClause(whereClause)
      const query = Backendless.Data.of('QuestionsAnswers')
      if (this.$route.params.entryId != null) {
        query
          .find(
            queryBuilder,
          )
          .then(researchsAnswers => {
            this.currentResearch.questionsAnswers = []
            that.totalRows = researchsAnswers.length
            this.fileName = `${this.$route.params.userId}_${this.$route.params.entryId}_${this.$route.params.userId}`
            if (researchsAnswers.length > 0) {
              this.fileName = `${researchsAnswers[0].userId.email}_${researchsAnswers[0].userId.objectId}_${researchsAnswers[0].created}`
            }
            researchsAnswers.forEach(async element => {
              if (element?.dn !== 'SPAM ') {
                that.items.push({
                  id: element?.objectId,
                  answerTime: element?.AnswerTime,
                  correctAnswerIndex: element?.CorrectAnswer,
                  userChoice: element?.UserChoice,
                  userChoiceValue:
                        element?.questionId?.Choices[element?.UserChoice - 1],
                  questions: element?.questionId,
                  description: element?.questionId?.DescriptionEN,
                  user: element?.userId,
                  created: element?.created,
                  isCorrect: element?.CorrectAnswer === element?.UserChoice ? 'Correct' : 'Wrong',
                })
              }
            })
            that.items.forEach(element => {
              that.itemsCsv.push({
                id: element?.id,
                answerTime: element?.answerTime,
                correctAnswerIndex: element?.correctAnswerIndex,
                userChoice: element?.userChoice,
                userChoiceValue: element?.userChoiceValue,
                availableChoices: JSON.stringify(element?.questions?.Choices),
                questions: element?.questions?.TitleEN,
                description: element?.questions?.DescriptionEN,
                audio: element?.questions?.Audio,
                video: element?.questions?.Video,
                image: element?.questions?.Image,
                user: element?.user?.email,
                created: this.formatDate(element?.created),
              })
            })
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    ResearchesTypes() {
      const that = this
      that.researchesTypes = []
      const query = Backendless.Data.of('ResearchesTypes')
      query
        .find()
        .then(results => {
          results.forEach(element => {
            this.researchesTypes.push({
              name: element?.name,
            })
          })
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
